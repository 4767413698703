@font-face {
  font-family: PierSans;
  src: url('assets/PierSans-Regular.otf');
}

@media only screen and (max-width: 600px) {
  .pnlm-container {
    height: calc(100vh - 56px) !important;
  }
}

.pnlm-about-msg {
  visibility: hidden;
}

.pnlm-about-msg a {
  visibility: hidden;
}